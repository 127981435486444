// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { Check, ErrorOutline } from '@material-ui/icons';
import IconButton from 'components/IconButton';
import RouteChangeNotification from 'sharedContainers/RouteChangeNotification';
import { notificationTypes, closeNotification } from './NotificationsActions';

import useStyles from './useStyles';

const TypeIcon = ({ type }) => {
  const classes = useStyles();

  switch (type) {
    case notificationTypes.SUCCESS:
      return (
        <div className={classes.messageIconSuccess}>
          <Check fontSize="inherit" />
        </div>
      );
    case notificationTypes.ERROR:
      return (
        <div className={classes.messageIconError}>
          <ErrorOutline fontSize="inherit" />
        </div>
      );
    default:
      return null;
  }
};
TypeIcon.propTypes = {
  type: PropTypes.string,
};

const Notifications = ({ routeNotification }) => {
  const notifications = useSelector(state => state.NotificationsReducer.notifications);
  const dispatch = useDispatch();

  const handleClose = id => () => {
    dispatch(closeNotification(id));
  };

  const classes = useStyles();

  return (
    <>
      {notifications.map((n, index) => (
        <Snackbar
          key={n.id}
          data-node="notification-bar"
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleClose(n.id)}
          ContentProps={{
            'aria-describedby': n.id,
          }}
          style={{
            '--message-num': index,
          }}
          classes={{ root: classes.pushDown }}
          message={
            <div className={classes.message}>
              <div className={classes.messageIcon}>
                <TypeIcon type={n.type} />
              </div>
              <div className={classes.messageText} data-node="notification-bar-message">
                {n.body}
              </div>
            </div>
          }
          action={
            <>
              <div className={classes.actions}>
                {n.actions.map((action, i) => (
                  <div key={i} className={classes.action}>
                    {action}
                  </div>
                ))}
              </div>
              <IconButton
                aria-label="close"
                key="close"
                icon="close"
                data-node="close-notification"
                onClick={handleClose(n.id)}
                className={classes.close}
              />
            </>
          }
        />
      ))}
      {routeNotification && <RouteChangeNotification />}
    </>
  );
};

Notifications.propTypes = {
  routeNotification: PropTypes.bool,
};

Notifications.defaultProps = {
  routeNotification: false,
};

export default React.memo(Notifications);
