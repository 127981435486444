import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  close: {
    color: theme.palette.common.white,
    marginLeft: 16,
  },

  message: {
    display: 'flex',
    alignItems: 'center',
  },

  messageIcon: {
    marginRight: 10,
    borderRadius: '10%',
  },

  messageIconSuccess: {
    background: theme.palette.statuses.success,
    width: 16,
    height: 16,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(12),
  },

  messageIconError: {
    background: theme.palette.statuses.danger,
    width: 16,
    height: 16,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(12),
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
  },

  action: {
    margin: '0 5px',

    '&:first-child': {
      marginLeft: 0,
    },

    '&:last-child': {
      marginRight: 0,
    },
  },

  pushDown: {
    transform: 'translateY(calc(var(--message-num) * 70px))',
    transition: 'transform 150ms',
    zIndex: 75000,
  },
}));
